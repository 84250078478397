@import "../../../global.scss";

.QA {
  position: relative;
  padding-top: 140px;
  &__imgs {
    display: inline-block;
    position: relative;
    padding-top: 20px;
    .person-img {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 5;
      width: 70px;
      height: 67px;
      object-fit: cover;
      -webkit-mask-box-image: url("../../../assets/images/mask.png");
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .greenBg-img {
      position: absolute;
      top: 18px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 76px;
      height: 71px;
    }
    .bg-img {
      height: 265px;
    }
  }
  .slick {
    &-dots {
      bottom: -10px;
      z-index: 15;
      z-index: 200;
      li {
        width: 13px;
        height: 13px;
        margin: 0 3px;
        button {
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            opacity: 0.5;
            background-color: $primaryColor;
            transition: opacity 0.6s ease;
          }
        }
        &.slick-active {
          button {
            &:before {
              width: 12px;
              height: 12px;
              margin-top: -1px;
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
  .list-group-item {
    padding-left: 40px;
    border: none;
    background-color: transparent;
    h6 {
      font-weight: bold;
    }
    p {
      font-size: 0.8rem;
      font-family: "PoppinsMedium";
    }
    .svg-inline--fa {
      position: absolute;
      font-size: 1.3rem;
      left: 10px;
      color: $primaryColor;
    }
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .QA {
    .row {
      flex-direction: column-reverse;
    }
    padding-top: 90px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
}
