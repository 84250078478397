@import "../../../global.scss";

.menu {
  position: absolute;
  z-index: 10000;

  &__content {
    border-radius: 4px;
    box-shadow: 0px 0px 3px #00000029;
    overflow: hidden;
    &.bg-light {
      background-color: #fff !important;
    }
    .navbar-brand {
      font-size: 0.9rem;
      color: $primaryColor !important;
      font-family: "PoppinsBold";
      .logo {
        width: 43px;
      }
    }
    .navbar-nav {
      a {
        cursor: pointer;
        font-size: 0.9rem;
        padding: 1rem !important;
        color: rgba(0, 0, 0, 0.5);
        font-family: "PoppinsMedium";
        &.active {
          color: white !important;
          background-color: $primaryColor;
        }
      }
    }
  }
}

/* BODY CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
body {
  position: relative;
}
.menu {
  transition: all 0.3s;
}

.scroll-down,
.scroll-up {
  .menu {
    position: fixed;
    top: 0;
    height: 55px;
    width: 100%;
    z-index: 10000000000000;
    &__content {
      transition: all 0.4s;
      transform: translate3d(0, -3rem, 0);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    }
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .menu {
    &.mt-5 {
      margin-top: 2rem !important;
    }
    &__content {
      .navbar-brand {
        font-size: 0.82rem;
        .logo {
          margin-left: 1rem !important;
        }
      }
      .navbar-nav {
        a {
          font-size: 0.8rem;
          padding: 1rem 0.4rem !important;
        }
      }
      .navbar-toggler {
        margin: 0 10px;
      }
    }
  }
  .scroll-down,
  .scroll-up {
    .menu {
      &__content {
        transform: translate3d(0, -2rem, 0) !important;
      }
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .menu {
    &__content {
      .navbar-nav {
        a {
          font-size: 0.85rem;
          padding: 1rem 0.6rem !important;
        }
      }
      .navbar-toggler {
        margin: 0 10px;
      }
    }
    .navbar-collapse {
      .navbar-nav {
        a {
          padding: 0.6rem !important;
        }
      }
    }
  }
}
