@import "../../../global.scss";

.contactSection {
  width: 100%;
  position: relative;
  padding-top: 140px;
  padding-bottom: 50px;
  &__content {
    padding-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc((300px - 181px) / 2);
    margin: 0 auto;
    .form-control::-webkit-input-placeholder {
      color: #585755;
      font-size: 0.9rem;
    }
    .form-control::placeholder {
      color: #585755;
      font-size: 0.9rem;
    }
  }
  .form-control {
    border: 0.5px solid #313131;
    border-radius: 30px;
    padding: 25px 30px;
    margin-bottom: 25px;
    background-color: transparent;
    resize: none;
    &:focus {
      box-shadow: none !important;
      border-color: $primaryColor !important;
    }
    &[readonly] {
      background-color: transparent;
    }
  }
  .ant-select-arrow {
    position: absolute;
    left: 93%;
  }
}
html[dir="rtl"] {
  .ant-select-arrow {
    transform: translateX(-20px);
  }
  .ant-input {
    font-family: "Cairo", sans-serif !important;
  }
}

// Form styling
.ant-btn,
.ant-form label {
  font-size: 1rem;
}

.ant-input {
  border: 0.5px solid #313131 !important;
  border-radius: 30px !important;
  padding: 15px 30px !important;
  background-color: transparent !important;
  width: 100% !important;
  resize: none !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
.ant-input:focus {
  border: 0.5px solid #313131;
  box-shadow: none !important;
  border-color: #6aaf65 !important;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.ant-form-item {
  margin-bottom: 25px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
  margin-left: 30px;
  color: red;
}
button.Send-btn {
  padding-bottom: 31px;
}
/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .contactSection {
    .form-control {
      font-size: 0.8rem;
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .contactSection {
    .form-control {
      font-size: 0.8rem;
    }
  }
}

.ant-select.ant-input.ant-select-single.ant-select-show-arrow {
  text-align: left;
}
