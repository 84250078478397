@import "../../global.scss";

.header {
  .row {
    position: relative;
    margin: 0 !important;
    &::before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 3px;
      width: 72px;
      height: 70px;
      z-index: 100;
      background: url(../../assets/images/points.png) no-repeat center center /
        contain;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -38px;
      right: 0;
      width: 85px;
      height: 120px;
      z-index: 100;
      background: url(../../assets/images/point-yellow.png) no-repeat center
        center / contain;
    }
  }
  &__pattern {
    height: 685px;
    color: white;
    padding: 16px 120px 16px 50px !important;
    flex-direction: column;
    background-color: $primaryColor;
    background: url(../../assets/images/pattern.png) no-repeat center center /
      cover;
    h6,
    h2 {
      color: white;
      text-transform: capitalize;
    }
    h2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      overflow: hidden;
    }
    .address {
      position: relative;
      padding: 0 35px;
      .svg-inline--fa {
        position: absolute;
        left: 0;
        top: 6px;
        font-size: 1.9rem;
      }
    }
  }
  &__slider {
    padding-top: 7.2rem;
    padding-right: 0 !important;
    .slick {
      &-slider {
        height: 480px;
        margin-left: -70px;
        background-color: white;
        border-radius: 7px;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 30%;
          bottom: 0;
          background: linear-gradient(
            to bottom,
            rgba(246, 246, 246, 0) 0%,
            rgba(255, 255, 255, 0) 0%,
            #00000074 100%
          );
          z-index: 100;
        }
        &-arrow {
          display: none;
        }
        img {
          height: 480px;
          object-fit: cover;
        }
      }
      &-dots {
        bottom: 20px;
        z-index: 15;
        z-index: 200;
        li {
          width: 13px;
          height: 13px;
          margin: 0 3px;
          button {
            &:before {
              content: "";
              width: 12px;
              height: 12px;
              border-radius: 50%;
              opacity: 0.5;
              background-color: white;
              transition: opacity 0.6s ease;
            }
          }
          &.slick-active {
            button {
              &:before {
                width: 13px;
                height: 13px;
                margin-top: -1px;
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
}
html[dir="rtl"] {
  h6,
  h2 {
    font-family: "Cairo", sans-serif !important;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .header {
    &__pattern {
      height: 540px;
      padding: 90px 30px 16px !important;
      p {
        font-size: 0.8rem;
      }
      .address {
        .svg-inline--fa {
          font-size: 1.7rem;
        }
      }
    }
    &__slider.pb-5 {
      padding-top: 1rem;
      padding-bottom: 0 !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      margin-top: -60px;
      .slick {
        &-slider {
          height: 150px;
          margin-left: 0;
          img {
            height: 150px;
          }
        }
      }
    }
    .row {
      &::after {
        display: none;
      }
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .header {
    &__pattern {
      padding: 16px 80px 16px 50px !important;
      .address {
        .svg-inline--fa {
          font-size: 1.7rem;
        }
      }
    }
  }
}
