@import "../../global.scss";

.Register {
  padding: 60px 0 0;
  .container-fluid {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .nav-tabs {
    direction: rtl;
    border-bottom: none;
    margin-top: 4rem !important;
    .nav-link {
      font-size: 0.85rem;
      padding: 1rem 0.4rem;
      margin-left: 1.5rem;
      min-width: 217px;
      border-radius: 40px;
      color: $primaryColor;
      border-color: $primaryColor;
      &.active {
        color: white;
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
  h2 {
    line-height: 1.8;
    margin-bottom: 4rem;
    border-bottom: 1px solid $primaryColor;
  }
  h5,
  h2 {
    color: $primaryColor;
    font-family: "PoppinsMedium";
  }
  .form-icon {
    position: relative;
    .form-control-file {
      position: absolute;
      height: 52px;
      line-height: 45px;
      left: 27px;
      opacity: 0;
    }
    .svg-inline--fa {
      position: absolute;
      right: 20px;
      top: 17px;
      color: $primaryColor;
    }
  }
  .btn-link {
    margin: 0 20px;
    color: #313131;
    text-decoration: underline;
    .svg-inline--fa {
      margin-right: 10px;
      color: $primaryColor;
    }
    &:hover {
      color: $primaryColor;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .Send-btn {
    margin-bottom: 5rem;
  }
}
html[dir="rtl"] {
  input[type="number"]::placeholder {
    text-align: right;
  }
  .custom-uploadBtn {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .uplodIcon {
    text-align: left;
  }
}
.ant-btn,
.ant-form label {
  font-size: 1rem;
}
.ant-input {
  border: 0.5px solid #313131 !important;
  border-radius: 30px !important;
  padding: 15px 30px !important;
  background-color: transparent !important;
  width: 100% !important;
  resize: none !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
.ant-input:focus {
  border: 0.5px solid #6aaf65 !important;
  box-shadow: none !important;
  color: #495057;
  background-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}
div.ant-upload {
  border: 0.5px solid #313131 !important;
  border-radius: 30px !important;
  padding: 15px 30px !important;
  background-color: transparent !important;
  width: 100% !important;
  resize: none !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
div.ant-upload:focus {
  border: 0.5px solid #313131;
  box-shadow: none !important;
  border-color: #6aaf65 !important;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

div.ant-upload button {
  border: 0;
  box-shadow: none !important;
  border-color: #6aaf65 !important;
  color: #6aaf65 !important;
  direction: rtl;
  width: 100%;
  text-align: left;
}
.ant-upload-list-item {
  width: 50%;
  text-align: left;
}
.ant-upload-list-item-name {
  color: #6aaf65 !important;
}
div.ant-upload button:hover {
  color: #6aaf65 !important;
}
div.ant-upload span {
  text-align: left;
}
.anticon.anticon-upload {
  float: right;
}

.ant-form-item {
  margin-bottom: 25px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
  margin-left: 30px;
  color: red;
}
span.ant-input-affix-wrapper.ant-input-password {
  border: 0;
  padding-left: 0;
}
span.ant-input-affix-wrapper.ant-input-password:focus {
  border: 0.5px;
  box-shadow: none !important;
  border-color: #6aaf65 !important;
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
  box-shadow: none;
}
.ant-row.ant-form-item.checkBox-antd {
  text-align: left;
  font-weight: 700;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4caf50;
  border-color: #4caf50;
}
label.ant-form-item-required {
  color: #4caf50;
}
.dynamicInput {
  width: 96% !important;
}
.dynamic-add-button {
  text-align: left;
}
.dynamic-add-button button {
  border: 0;
  box-shadow: none;
  font-weight: 400;
}

.dynamic-add-button button span svg {
  color: #4caf50;
}
.dynamic-add-button button span:hover {
  color: #000;
}
.ant-row.ant-form-item.submit-antd button {
  margin: 0 auto;
  padding: 10px 120px;
  border-radius: 50px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 50px;
}
.ant-row.ant-form-item.submit-antd button:hover {
  color: #000;
}
span.anticon.anticon-minus-circle.dynamic-delete-button {
  color: red;
  margin-left: 8px;
}
//checkBox
.form-group {
  position: relative;
}
.form-check-input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  left: 36px;
  height: 21px;
  width: 90px;
  top: -3px;
  z-index: 1000;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    border: 1px solid #adaaaa;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  // Box checked
  &:checked + label:before {
    background: $primaryColor;
  }
  // Checkmark
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.form-check-label {
  font-weight: bold;
  a {
    color: $primaryColor;
    text-decoration: underline;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .Register {
    .container-fluid {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    h2 {
      font-size: 1.3rem;
    }
    h5 {
      font-size: 1rem;
    }
    .col-md-12 {
      padding: 0;
    }
    .nav-tabs {
      .nav-link {
        margin-left: 0;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
    .Send-btn {
      padding: 10px 65px;
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .Register {
    .container-fluid {
      padding-left: 70px !important;
      padding-right: 70px !important;
    }
  }
}
