@import "../../../global.scss";

.HowItWork {
  padding-top: 140px;
  .row {
    height: 255px;
  }
  h6 {
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    font-family: "PoppinsMedium";
    color: #313131;
  }
  .slideShow {
    position: relative;
    .screen-img,
    .carousel {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .screen-img {
      width: 350px;
      height: 280px;
      z-index: 1;
    }
    .carousel {
      top: 14px;
      height: 180px;
      width: 320px;
      z-index: 5;
      &-inner,
      &-item,
      img {
        height: 100%;
        border-radius: 0;
      }
      img {
        width: 100%;
        // object-fit: cover;
      }
      &-indicators {
        display: none;
      }
      &-control {
        &-prev {
          left: -65px;
        }
        &-next {
          right: -65px;
        }
        &-next,
        &-prev {
          color: #313131;
          font-size: 1.5rem;
        }
      }
    }
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .HowItWork {
    padding-bottom: 285px;
    .slideShow {
      .screen-img,
      .carousel {
        position: absolute;
        left: 0;
      }
      .screen-img {
        width: 215px;
        height: 172px;
      }
      .carousel {
        top: 8px;
        left: 0;
        height: 111px;
        width: 197px;
        &-control {
          &-next {
            right: -38px;
          }
          &-prev {
            left: -38px;
          }
        }
      }
    }
    padding-top: 90px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .HowItWork {
    .slideShow {
      position: relative;
      .screen-img,
      .carousel {
        position: absolute;
        left: 0;
        right: auto;
        margin: 0;
      }
      .screen-img {
        width: 317px;
        height: 254px;
      }
      .carousel {
        top: 12px;
        left: 13px;
        height: 164px;
        width: 291px;
        &-control {
          &-next {
            right: -50px;
          }
          &-prev {
            left: -50px;
          }
        }
      }
    }
    p {
      font-size: 0.8rem;
    }
  }
}
