@import "../../../global.scss";

.features {
  padding-top: 140px;
  .list-group {
    .list-group-item {
      background-color: transparent;
      p {
        font-size: 1rem;
        font-family: "PoppinsMedium";
        color: #313131;
      }
    }
  }
  .about__imgs {
    .small-img {
      bottom: -52px;
      left: -85px;
    }
    .smallest-img {
      height: 85px;
      top: 8px;
      left: -30px;
    }
    .white-img {
      left: -86px;
      bottom: -59px;
    }
    .white-img2 {
      height: 93px;
      left: -33px;
      top: 3px;
    }
  }
  .hasLineBreak {
    white-space: pre-line;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .features {
    .list-group {
      .list-group-item {
        p {
          font-size: 0.9rem;
        }
      }
    }
    padding-top: 90px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .features {
    .about {
      &__imgs {
        .small-img {
          bottom: -40px;
          left: -65px;
        }
        .white-img {
          left: -68px;
          bottom: -46px;
        }
        .white-img2 {
          left: -63px;
          top: 5px;
        }
        .smallest-img {
          top: 10px;
          left: -61px;
        }
      }
    }
    .list-group {
      .list-group-item {
        padding: 0.75rem 0rem;
        p {
          font-size: 0.87rem;
        }
      }
    }
  }
}
