@import "./theme/variables.scss";
@import "~bootstrap/scss/bootstrap";

// global Sass styles
@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: "PoppinsMedium";
  src: url(./assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "PoppinsBold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
.tabIcon,
a {
  font-family: "Poppins";
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container-fluid {
  display: inline-block;
  padding-left: 100px !important;
  padding-right: 100px !important;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  opacity: 0.8;
  background: $primaryColor;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
.list-group .list-group-item {
  border: none;
  background-color: transparent;
}
.slick-arrow {
  display: none !important;
}

.ant-checkbox + span {
  font-weight: bold;
}

.ant-input-affix-wrapper {
  padding: 0;
  border: none;
  position: relative;
  .ant-input {
    padding: 15px 45px !important;
  }
}
.input-wIcon {
  position: absolute;
  left: 25px;
  z-index: 10;
}
.ant-notification {
  top: 125px !important;
}

.custom-dot-list-style {
  text-align: center;
  button {
    background-color: #b6b6b6;
    border-color: #e4e4e4;
    &:focus {
      outline: none !important;
    }
  }
}
.react-multi-carousel-dot--active {
  button {
    background-color: #6aaf65;
    border-color: #e4e4e4;
  }
}

//submit btn
.Send-btn {
  margin: 0 auto;
  padding: 10px 120px;
  border-radius: 50px;
  color: white !important;
  border-color: $primaryColor;
  background-color: $primaryColor;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.9;
    border-color: $primaryColor;
    background-color: $primaryColor;
  }
  &:focus {
    box-shadow: none;
    border-color: $primaryColor;
    background-color: $primaryColor;
  }
}

//modal
.modal {
  z-index: 50000;
  top: calc((100% - 340px) / 2);
  &-backdrop.show {
    z-index: 10000;
  }
  &-content {
    width: 80%;
    border-radius: 2rem;
    h5 {
      font-weight: bold;
    }
    p {
      color: #313131;
    }
  }
  &-header {
    border: none;
  }
  &-body {
    padding: 1rem 2.5rem;
    margin-top: -30px;
    .svg-inline--fa {
      font-size: 3.5rem;
      color: $primaryColor;
      display: block;
      margin: 0 auto;
      margin-bottom: 1.4rem;
    }
  }
  &-footer {
    justify-content: center;
    padding: 0.75rem;
    border-top: none;
    padding: 0rem 0.75rem 2.5rem 0.75rem;
  }
}

.scrollTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  z-index: 2000000;
  opacity: 0;
  cursor: pointer;
  transition: all 0.4s;
  background-color: $primaryColor;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  .svg-inline--fa {
    color: white;
    font-size: 1.4rem;
  }
}
.show-ScorllUpBtn .scrollTopBtn {
  opacity: 1;
  transition: all 0.4s;
}

a {
  color: black;
  &:hover {
    color: black;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}
html[dir="rtl"] {
  .elements-aligns-right {
    text-align: right;
  }

  body {
    font-family: "Cairo", sans-serif !important;
  }
  .navbar-nav a {
    font-family: "Cairo", sans-serif !important;
  }
  .langLink {
    font-family: "Poppins", sans-serif !important;
  }
  .registerTopBtns {
    display: flex;
    flex-direction: row-reverse;
  }
  .features-img {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 150px;
  }
  .about-icons {
    position: relative;
    left: 10px;
    bottom: 10px;
  }
  .QA-icons {
    float: right !important;
    margin-left: 10px !important;
    position: static !important;
  }
}
.langLink {
  font-family: "Cairo", sans-serif !important;
}
.slick-slider {
  width: inherit !important;
}
// ._navbar {
//   // margin-left: auto;
//   // position: fixed ;
//   // z-index: 999999;
// }
/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h6,
  p {
    font-size: 0.9rem;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .scroll-down,
  .scroll-up {
    .header {
      &__menu {
        transform: translate3d(0, 0, 0);
        top: 0;
        position: fixed;
        width: 100%;
      }
    }
  }

  //modal
  .modal-content {
    width: 90%;
    margin: 0 auto;
  }
  .modal-body {
    padding: 1rem 1.2rem;
  }
  .Send-btn {
    padding: 10px 65px;
  }

  //checkbox
  .form-check-input {
    + label {
      font-size: 0.8rem;
      &:before {
        width: 20px;
        height: 20px;
      }
    }
    &:checked {
      + label:after {
        left: 5px;
        top: 10px;
      }
    }
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h6,
  p {
    font-size: 0.9rem;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .container-fluid {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .scroll-down,
  .scroll-up {
    .header {
      &__menu {
        transform: translate3d(0, 0, 0);
        top: 0;
        position: fixed;
        width: 100%;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }
}
.carousel-control-next {
  left: auto !important;
}
.ant-select-selector
// .ant-select-selection-search,
// .ant-select-selection-search-input
{
  border: none !important;
}
textarea::-webkit-scrollbar {
  // width: 12px;
  // background-color: #f5f5f5;
  border-radius: 30px !important;
}
textarea {
  overflow: hidden;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  // background-color: #4285F4;
}
