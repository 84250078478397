@import "../../global.scss";

.footer {
  width: 100%;
  padding: 60px 0 0;
  .container-fluid {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .logo {
    width: 70px;
  }
  h6 {
    color: #000000;
    font-weight: bold;
    font-family: "PoppinsMedium";
  }
  p,
  a {
    color: #313131;
    font-size: 0.9rem;
  }
  a {
    display: block;
    margin-bottom: 10px;
    &:hover {
      color: $primaryColor;
    }
  }
}
html[dir="rtl"] {
  h6,
  p,
  a {
    font-family: "Cairo", sans-serif !important;
  }
  .footer-logo {
    float: right;
  }
  .footer-p {
    clear: both;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .footer {
    padding: 40px 0;
    .container-fluid {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .row {
      padding: 0 10px;
      margin: 0 -10px;
    }
    img {
      width: 190px;
    }
    p {
      font-size: 0.9rem;
    }
    .col-6 {
      margin-bottom: 14px;
      margin-top: 20px;
      padding: 0;
    }
    h6 {
      font-size: 0.9rem;
    }
    a {
      font-size: 0.8rem;
    }
    .footer-logo-section {
      justify-content: center;
    }
    .footer-logo {
      transform: translateX(-100%);
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .footer {
    .container-fluid {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
  }
}
