@import "../../../global.scss";

.about {
  position: relative;
  padding-top: 140px;
  &__imgs {
    display: inline-block;
    position: relative;
    padding-top: 20px;
    img {
      -webkit-mask-box-image: url("../../../assets/images/mask.png");
    }
    .large-img {
      height: 238px;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .small-img {
      height: 150px;
      position: absolute;
      bottom: -40px;
      left: -100px;
      z-index: 5;
    }
    .white-img {
      -webkit-mask-box-image: none;
      height: 161px;
      position: absolute;
      bottom: -46px;
      z-index: 2;
      left: -101px;
    }
  }

  .list-group {
    .list-group-item {
      h6 {
        font-weight: bold;
      }
      p {
        font-size: 0.9rem;
      }
    }
    img {
      position: absolute;
      left: -6px;
      width: 17px;
      height: 20px;
      object-fit: contain;
      color: $primaryColor;
    }
  }
}
html[dir="rtl"] {
  h4,
  h2 {
    font-family: "Cairo", sans-serif !important;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .about {
    &__imgs {
      display: none;
    }
    .list-group {
      .list-group-item {
        padding-right: 0;
      }
    }
    padding-top: 90px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .about {
    &__imgs {
      .large-img {
        height: 190px;
      }
      .small-img {
        height: 110px;
        bottom: -13px;
        left: -56px;
      }
      .white-img {
        height: 120px;
        bottom: -19px;
        left: -58px;
      }
    }
    .list {
      &-col {
        padding-right: 0;
      }
      &-group {
        .list-group-item {
          padding-right: 0;
          p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
