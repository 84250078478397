@import "../../../global.scss";

.downloadSection {
  position: relative;
  margin-top: 140px;
  padding: 95px 0;
  background-color: $primaryColor;
  background-image: url("../../../assets/images/application-section-bg.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  .row {
    color: white;
    p {
      opacity: 0.7;
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }
    h3 {
      color: white;
    }
  }
  .row-btns {
    width: 400px;
    margin: 0 auto;
  }
  &__btn.btn {
    min-width: 160px;
    border: none;
    padding-left: 45px;
    font-size: 0.7rem;
    color: #5c5c5c;
    position: relative;
    border-radius: 35px;
    background: white;
    .svg-inline--fa {
      position: absolute;
      left: 15px;
      top: 10px;
      font-size: 1.5rem;
      color: $primaryColor;
      &.fa-apple {
        top: 6px;
        font-size: 1.8rem;
      }
    }
    p {
      opacity: 1;
      line-height: 1.2;
      font-size: 0.85rem;
      font-family: "PoppinsMedium";
    }
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .downloadSection {
    padding: 70px 0;
    background-image: none;
    .row-btns {
      width: 225px;
    }
    &__btn.btn {
      margin-bottom: 25px;
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .downloadSection {
    padding: 70px 0;
    h3 {
      margin-bottom: 1rem !important;
    }
    .row-btns {
      .col-md-6 {
        padding: 0;
        text-align: left !important;
      }
    }
    &__btn.btn {
      width: 135px;
      min-width: 135px;
      padding-left: 40px;
      font-size: 0.55rem;
      p {
        font-size: 0.75rem;
      }
      .svg-inline--fa {
        font-size: 1.25rem;
        &.fa-apple {
          font-size: 1.5rem;
        }
      }
    }
  }
}
