@import "../../global.scss";

.home {
  .container-fluid {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .title {
    padding: 0 155px;
    h6 {
      display: inline-block;
      margin-bottom: 20px;
      color: $primaryColor;
      position: relative;
      font-size: 1.2rem;
      &::before {
        content: "";
        position: absolute;
        top: -32px;
        right: -22px;
        width: 52px;
        height: 60px;
        background: url("../../assets/images/doodle.png") no-repeat center
          center / contain;
      }
    }
    h2 {
      margin-bottom: 40px;
    }
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .home {
    .container-fluid {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .title {
      padding: 0 10px;
      h2 {
        font-size: 1.4rem;
      }
      h6::before {
        width: 42px;
      }
    }
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .home {
    .container-fluid {
      padding-left: 70px !important;
      padding-right: 70px !important;
    }
    .title {
      padding: 0 30px;
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}
