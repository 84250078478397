@import "../../../global.scss";

.services {
  position: relative;
  padding-top: 140px;
  &__col {
    padding: 0 45px !important;
  }
  &__card {
    padding: 30px 25px !important;
    transition: all 0.3s ease-in-out;
    img {
      width: 100px;
      min-height: 91px;
      -webkit-mask-box-image: url("../../../assets/images/mask.png");
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;
    }
    h5 {
      font-size: 1.1rem;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "PoppinsMedium";
    }
  }
}
html[dir="rtl"] {
  h5,
  p {
    font-family: "Cairo", sans-serif !important;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .services {
    &__col {
      padding: 0 20px !important;
    }
    &__card {
      padding: 20px 15px !important;
    }
    padding-top: 90px;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .services {
    &__col {
      padding: 0 !important;
    }
    &__card {
      padding: 30px 10px !important;
      h5 {
        font-size: 0.95rem;
      }
      p {
        font-size: 0.85rem;
      }
    }
  }
}
