@import "../../../global.scss";

.verification {
  h2 {
    margin-top: 100px;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .verification {
    h2 {
      font-size: 1.3rem;
    }
    h5{
      font-size: 1rem;
    }
  }
}
.redIcon{
  color: red !important;
}