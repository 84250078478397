@import "../../../global.scss";

.partners {
  padding-top: 140px;
  .title {
    padding: 0 295px;
  }
  h3 {
    font-size: 1.3rem;
    font-family: "PoppinsMedium";
  }

  &__card {
    z-index: 1000;
    max-width: 370px;
    padding: 70px 10px 70px !important;
    &__mt {
      margin-top: -60px;
    }
    div {
      position: relative;
      padding: 30px 10px !important;
      box-shadow: 2px 4px 14px #e4e4e4;
      border-radius: 20px;
      background-color: white;
      span {
        display: block;
        font-size: 0.8rem;
        margin-bottom: 5px;
        .svg-inline--fa {
          color: #ffce00;
        }
      }
      &:hover {
        cursor: pointer;
        img {
          transform: scale(1.1);
        }
      }
      &:after {
        animation: shine 3.7s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
        background: rgba(255, 255, 255, 0.2);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.13) 0%,
          rgba(255, 255, 255, 0.13) 77%,
          rgba(255, 255, 255, 0.5) 92%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
    img {
      width: 80px;
      height: 70px;
      margin: 0 auto;
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;
      -webkit-mask-box-image: url("../../../assets/images/mask.png");
    }
    h5 {
      font-size: 1rem;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "PoppinsMedium";
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
    p {
      font-size: 0.75rem;
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

/* -------------------------------- */
/*  responsive */
/* -------------------------------- */
/* // Small devices (landscape phones, 280px to 768px) */
@media (min-width: 280px) and (max-width: 768px) {
  .partners {
    .title {
      padding: 0 40px;
    }
    h3 {
      font-size: 1.05rem;
    }
    &__card {
      padding: 70px 10px 70px !important;
      div {
        padding: 20px 7px !important;
      }
      h5 {
        font-size: 0.9rem;
      }
    }
    padding-top: 90px;
  }
}
html[dir="rtl"] {
  h3,
  h5,
  p {
    font-family: "Cairo", sans-serif !important;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1125px) {
  .partners {
    .title {
      padding: 0 120px;
    }
    h3 {
      font-size: 1.15rem;
    }
    &__card {
      padding: 70px 20px 70px !important;
      h5 {
        font-size: 0.9rem;
      }
    }
  }
}
